import { useState, useEffect } from "react";
import axios from "axios";
import "react-notifications-component/dist/theme.css";
import { Store } from "react-notifications-component";
import "animate.css/animate.min.css";
import "animate.css/animate.compat.css";

const UpdateAPI = (props) => {
  const [userData, setUserData] = useState(props);

  const apiEndpoint_Characters = process.env.REACT_APP_API_ENDPIONT_CHARACTERS;
  const apiEndpoint_Bosses = process.env.REACT_APP_API_ENDPIONT_BOSSES;
  const apiEndpoint = process.env.REACT_APP_API_ENDPIONT;
  const authString = process.env.REACT_APP_API_KEY;
  const headers = {
    Authorization: `Basic ${btoa(authString)}`,
  };

  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [weapon, setWeapon] = useState("");
  const [elements, setElements] = useState("");
  const [rarity, setRarity] = useState("");
  const [select, setSelect] = useState(false);
  const [display, setDisplay] = useState({});
  const [image_url, setImage_url] = useState("");
  const [refresh, setRefresh] = useState(true);

  const [existingData, setExistingData] = useState([]);
  const [existingDataBoss, setExistingDataBoss] = useState([]);
  const [loading_character, setLoading_character] = useState(true);
  const [loading_boss, setLoading_boss] = useState(true);

  useEffect(() => {
    setUserData(props.userData);
  }, [props]);

  //Login Check
  // useEffect(() => {
  //   if (userData.user !== process.env.REACT_APP_LOGIN_USER) {
  //     if (userData.password !== process.env.REACT_APP_LOGIN_PASSWORD) {
  //       return;
  //     } else {
  //       console.log("login success : ", userData.login);
  //     }
  //   }
  // }, [userData]);

  //Refresh Web
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // For modern browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  //Refresh Api
  const RefreshApi = () => {
    // console.log(Store);
    if (userData.login) {
      setRefresh((e) => !e);
    } else {
      Store.addNotification({
        title: "You need yo login first!",
        message: `You need yo login first.`,
        type: "warning",
        insert: "top",
        container: "top-left",
        dismiss: {
          duration: 7000,
          onScreen: true,
          pauseOnHover: true,
        },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
      });
    }
  };
  //Refresh Api
  useEffect(() => {
    if (userData.login) {
      setLoading_character(true);
      setLoading_boss(true);
      axios
        .get(`${apiEndpoint_Characters}`, {
          headers: headers,
        })
        .then((response) => {
          setExistingData(response.data);
          setLoading_character(false);
        })
        .catch((error) => {
          console.error("Error fetching data Characters:", error);
        });

      axios
        .get(`${apiEndpoint_Bosses}`, {
          headers: headers,
        })
        .then((response) => {
          setExistingDataBoss(response.data);
          setLoading_boss(false);
        })
        .catch((error) => {
          console.error("Error fetching data Bosses:", error);
        });

      Store.addNotification({
        title: "API has refresh!",
        message: `API has refresh.`,
        type: "default",
        insert: "top",
        container: "top-left",
        dismiss: {
          duration: 7000,
          onScreen: true,
          pauseOnHover: true,
        },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
      });
    } else {
      return;
    }
  }, [refresh]);

  // Post Characters
  const PostCharacters = async () => {
    RefreshApi();
    const disElement = `dis${elements}`;
    const disWeapon = `dis${weapon}`;
    let R = "";
    if (rarity === "4") {
      R = "Four";
    } else {
      R = "Five";
    }

    const disRarity = `dis${R}`;
    const dataToPost = {
      id: Number(id),
      name: name,
      weapon: weapon,
      elements: elements,
      rarity: Number(rarity),
      select: select,
      display: {
        real: true,
        disSearch: true,
        [disRarity]: true,
        [disElement]: true,
        [disWeapon]: true,
      },
    };
    try {
      const idExists = existingData.some(
        (character) => character.id === Number(id)
      );
      if (idExists) {
        Store.addNotification({
          title: "ID already exists!",
          message: `Character with ID ${id} already exists.`,
          type: "warning",
          insert: "top",
          container: "top-left",
          dismiss: {
            duration: 7000,
            onScreen: true,
            pauseOnHover: true,
          },
          animationIn: ["animate__animated animate__fadeIn"],
          animationOut: ["animate__animated animate__fadeOut"],
        });
        return;
      } else {
        const response = await axios.post(`${apiEndpoint}cpost`, dataToPost, {
          headers: headers,
        });
        console.log("Data posted successfully:", response.data);
        Store.addNotification({
          title: "Data posted successfully!!",
          message: `${dataToPost.id}. ${dataToPost.name} added to Database!!.`,
          type: "success",
          insert: "top",
          container: "top-left",
          dismiss: {
            duration: 7000,
            onScreen: true,
            pauseOnHover: true,
          },
          animationIn: ["animate__animated animate__fadeIn"],
          animationOut: ["animate__animated animate__fadeOut"],
        });
      }
    } catch (error) {
      console.error("Error posting data:", error);
      Store.addNotification({
        title: "Error posting data character!!",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "top-left",
        dismiss: {
          duration: 5000,
          onScreen: true,
          pauseOnHover: true,
        },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
      });
    }
  };

  const PostBoss = async () => {
    RefreshApi();
    const idExists = existingDataBoss.some((boss) => boss.id === Number(id));
    try {
      const data = {
        id: id,
        name: name,
        select: select,
        display: true,
      };

      if (idExists) {
        Store.addNotification({
          title: "ID already exists!",
          message: `Boss with ID ${id} already exists.`,
          type: "warning",
          insert: "top",
          container: "top-left",
          dismiss: {
            duration: 7000,
            onScreen: true,
            pauseOnHover: true,
          },
          animationIn: ["animate__animated animate__fadeIn"],
          animationOut: ["animate__animated animate__fadeOut"],
        });
        return;
      } else {
        const response = await axios.post(
          `https://ill-erin-oyster-robe.cyclic.app/bpost`,
          data,
          { headers: headers }
        );
        console.log("Data posted successfully:", response.data);
        Store.addNotification({
          title: "Data posted successfully!!",
          message: `${data.id}. ${data.name} added to Database!!.`,
          type: "success",
          insert: "top",
          container: "top-left",
          dismiss: {
            duration: 7000,
            onScreen: true,
            pauseOnHover: true,
          },
          animationIn: ["animate__animated animate__fadeIn"],
          animationOut: ["animate__animated animate__fadeOut"],
        });
      }
    } catch (error) {
      console.error("Error posting boss:", error);
      Store.addNotification({
        title: "Error posting data boss!!",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "top-left",
        dismiss: {
          duration: 5000,
          onScreen: true,
          pauseOnHover: true,
        },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
      });
    }
  };

  // Delete Character
  const DeleteCharacter = async () => {
    const nameExists = existingData.map((char) =>
      char.name === name ? true : false
    );
    try {
      if (nameExists) {
        const response = await axios.delete(`${apiEndpoint}cdelete/${name}`, {
          headers: headers,
        });
        console.log(`Data delete successfully:`, response.data);
        Store.addNotification({
          title: "Data delete successfully!!",
          message: `${name} deleted in Database!!.`,
          type: "success",
          insert: "top",
          container: "top-left",
          dismiss: {
            duration: 7000,
            onScreen: true,
            pauseOnHover: true,
          },
          animationIn: ["animate__animated animate__fadeIn"],
          animationOut: ["animate__animated animate__fadeOut"],
        });
      } else {
        console.log("something worng");
      }
    } catch (error) {
      console.error("Error delete characters:", error);
      Store.addNotification({
        title: "Error delete data characters!!",
        message: `${error}`,
        type: "danger",
        insert: "top",
        container: "top-left",
        dismiss: {
          duration: 5000,
          onScreen: true,
          pauseOnHover: true,
        },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
      });
    }
  };

  // All update
  const UpdateAllCharacter = async () => {
    try {
      const char = name;
      const data = {
        image_url: image_url,
      };
      const response = await axios.put(`${apiEndpoint}cupdate/${char}`, data, {
        headers: headers,
      });
      console.log("Update character successfully:", response.data);
      Store.addNotification({
        title: "Update Data successfully!!",
        message: `Image ${name} added in Database!!.`,
        type: "success",
        insert: "top",
        container: "top-left",
        dismiss: {
          duration: 7000,
          onScreen: true,
          pauseOnHover: true,
        },
        animationIn: ["animate__animated animate__fadeIn"],
        animationOut: ["animate__animated animate__fadeOut"],
      });
      setName("");
      setImage_url("");
    } catch (err) {
      console.error("Error update characters:", err);
    }
  };

  return (
    <>
      {userData.login && (
        <div>
          {/* <div className="ml-10 top-10 text-2xl text-white absolute">
            Hello Admin
          </div> */}
          <div className="text-center text-white mt-10 text-3xl">
            <h1>Adjust API</h1>
            <span>
              <button
                onClick={RefreshApi}
                className="bg-green-600 rounded-xl text-2xl right-0 mr-16 top-10 absolute p-2 text-white hover:ring-2"
              >
                Re api
              </button>
            </span>
            <div className="mt-10 flex justify-center">
              <h1 className="mr-5">ID</h1>
              <input
                type="text"
                value={id}
                onChange={(e) => setId(e.target.value)}
                className="text-center w-[500px] h-10 text-black focus:ring-0 focus:border-none focus:outline-none rounded-md p-1"
              />
              {/* <button
            className=" ml-5 bg-gray-500 px-5 py-0 rounded-md "
            // onClick={}
          >
            +
          </button>
          <button
            className="ml-5 bg-gray-500 px-5 rounded-md"
            // onClick={}
          >
            -
          </button> */}
            </div>
            <div className="flex mt-5 justify-center">
              <h1 className="mr-5">Name</h1>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="text-center w-[500px] h-10 text-black focus:ring-0 focus:border-none focus:outline-none rounded-md p-1"
              />
            </div>
            <div className="flex justify-center mt-5">
              <h1 className="mr-5">Weapon</h1>
              <select
                value={weapon}
                onChange={(e) => setWeapon(e.target.value)}
                className="text-center w-[500px] h-10 text-black focus:ring-0 focus:border-none focus:outline-none rounded-md p-1"
              >
                <option value="">Select a Weapon</option>
                <option value="Sword">Sword</option>
                <option value="Claymore">Claymore</option>
                <option value="Bow">Bow</option>
                <option value="Polearm">Polearm</option>
                <option value="Catalyst">Catalyst</option>
              </select>
            </div>
            <div className="flex justify-center mt-5">
              <h1 className="mr-5">Elements</h1>
              <select
                value={elements}
                onChange={(e) => setElements(e.target.value)}
                className="text-center w-[500px] h-10 text-black focus:ring-0 focus:border-none focus:outline-none rounded-md p-1"
              >
                <option value="">Select a Elements</option>
                <option value="Anemo">Anemo</option>
                <option value="Cryo">Cryo</option>
                <option value="Dendro">Dendro</option>
                <option value="Electro">Electro</option>
                <option value="Geo">Geo</option>
                <option value="Hydro">Hydro</option>
                <option value="Pyro">Pyro</option>
              </select>
            </div>
            <div className="flex justify-center mt-5">
              <h1 className="mr-5">Rarity</h1>
              <select
                value={rarity}
                onChange={(e) => setRarity(e.target.value)}
                className="text-center w-[500px] h-10 text-black focus:ring-0 focus:border-none focus:outline-none rounded-md p-1"
              >
                <option value="">Select a Rarity</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="flex mt-5 justify-center">
              <h1 className="mr-5">Image_url</h1>
              <input
                type="text"
                value={image_url}
                onChange={(e) => setImage_url(e.target.value)}
                className="text-center w-[500px] h-10 text-black focus:ring-0 focus:border-none focus:outline-none rounded-md p-1"
              />
            </div>
            <div className="flex justify-center mt-10">
              <button
                className="bg-green-600 rounded-xl text-2xl p-2 mr-5"
                onClick={PostCharacters}
              >
                Post Characters
              </button>
              <button
                className="bg-green-600 rounded-xl text-2xl p-2"
                onClick={PostBoss}
              >
                Post Bosses
              </button>
            </div>
            <div className="flex justify-center mt-10">
              <button
                className="bg-red-600 rounded-xl text-2xl p-2 mr-5"
                onClick={DeleteCharacter}
              >
                Delete Characters
              </button>
            </div>
            <div className="flex justify-center mt-10">
              <button
                className="bg-orange-500 rounded-xl text-2xl p-2 mr-5"
                onClick={UpdateAllCharacter}
              >
                {"Custom (add image_url)"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateAPI;
