import NavBar from "../components/NavBar";
import SiteBar from "./components/SiteBar";
import Home from "./components/Home";
import ProfilePage from "./components/Profile";
import About from "./components/About";

const Profile = () => {
  return (
    <>
      <div>
        <div className="mobile:hidden">
          <SiteBar />
        </div>
        <Home />
        <ProfilePage />
        <About />
      </div>
    </>
  );
};

export default Profile;
