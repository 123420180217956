import React from "react";
import LandingPage from "./LandingPage/app";
function App() {
  return (
    <>
      <LandingPage />
    </>
  );
}

export default App;
