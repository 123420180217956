import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Genshin_randomizer from "./GenshinRandomizer/App";
import LandingPage from "./App";
import Profile from "./Profile/app";
import UpdateApiMainPage from "./UpdateDataApi/UpdateApiMainPage";
import Test from "./Test/Test";
const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/project/genshinrandomizer",
    element: <Genshin_randomizer />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/updateapi",
    element: <UpdateApiMainPage />,
  },
  {
    path: "/test",
    element: <Test />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
