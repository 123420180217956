import "./index.css";
import NavBar from "../components/NavBar";
const LandingPage = () => {
  return (
    <>
      <NavBar />
      <div className="flex justify-center items-center h-[75vh]">
        <div>
          <div className="text-red-600 text-center text-[7vw] select-none">
            Welcome to My Website!!
          </div>
          <p className="text-center text-[1.5vw] select-none text-white ">Thank You for Visiting</p>
      </div>
        </div>
    </>
  );
};

export default LandingPage;
