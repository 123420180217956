const About = () => {
  return (
    <>
      <div className="about" id="about">
        <div className="absolute top-[210%] left-[35%]">{/* Nothing */}</div>
      </div>
    </>
  );
};

export default About;
