import React from "react";
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faHouse,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";

const profile_img = require("../assets/profile/profile.png");

const SiteBar = () => {
  return (
    <>
      <div className="z-10 select-none fixed bg-[rgba(0,0,0,.585)] h-[100vh] w-[15%] block shadow-[.2em_5px_1em_1px_rgba(0,0,0,0.722)] text-center ">
        <ul className="m-0 p-0 text-center">
          <li className="m-0 p-0 text-[2vw] left-[3.25%] mb-[20%] ease-in-out duration-200">
            <img
              src={profile_img}
              className="inline h-auto w-[10vw] rounded-full mb-[15%] mt-[15%] shadow-[1px_0_10px_5px_rgb(255,215,0)] text-center hover:scale-[120%] hover:shadow-[1px_0_13px_7px_rgb(255,215,0)] ease-in-out duration-200"
            />
          </li>
          <li className="m-0 p-0 text-[2vw] left-[3.25%] mb-[20%] ease-in-out duration-500 hover:scale-[130%]">
            <a href="#home" className="text-white hover ">
              <FontAwesomeIcon icon={faHouse} className="text-[2vw] mr-3" />
              Home
            </a>
          </li>
          <li className="m-0 p-0 text-[2vw] left-[3.25%] mb-[20%] ease-in-out duration-500 hover:scale-[130%]">
            <a href="#profile" className="text-white hover">
              <FontAwesomeIcon
                icon={faAddressCard}
                className="text-[2vw] mr-3"
              />
              Profile
            </a>
          </li>
          <li className="m-0 p-0 text-[2vw] left-[3.25%] mb-[20%] ease-in-out duration-500 hover:scale-[130%]">
            <a href="#about" className="text-white hover">
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="text-[2vw] mr-3"
              />
              About
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SiteBar;
