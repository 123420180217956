import React from "react";
import "../index.css";

// const ProfilePage = () => {
//   return (
//     <>
//       <div id="profile" className="profile">
//         <div className="absolute left-[30%] top-[125%] w-2/4">
//           <div className="">
//             <h className="text-white text-[7vh] shadowtext">I'm </h>
//             <br />
//             <h className="text-yellow-300 text-[10vh] ml-[10%] shadowtext font-serif">
//               Suwat Santimalai
//             </h>
//             <h className="text-white text text-[6vh]">
//               <br />
//               <h className="ml-[6%] shadowtext">Fontend</h> <br />
//               <h className="ml-[28%] shadowtext">and</h> <br />
//               <h className="ml-[35%] shadowtext">little Backend</h>
//             </h>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

const ProfilePage = () => {
  return (
    <>
      <div id="profile" className="profile">
        <div className="absolute left-[25%] top-[130%] w-[70%]">
          <div className="">
            <span className="text-white text-[7vh] mobile:text-[3vh] shadowtext">
              I'm{" "}
              <span className="text-yellow-300 text-[5vh] mobile:text shadowtext font-serif">
                Suwat Santimalai
              </span>
            </span>
            <br />
            <span className="text-white text text-[3vh] mobile:text-[1.5vh]">
              {"<Fontend Developer />"}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
