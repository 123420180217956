import { useState, useEffect } from "react";
import "react-notifications-component/dist/theme.css";
import { ReactNotifications, Store } from "react-notifications-component";
import "animate.css/animate.min.css";
import "animate.css/animate.compat.css";

import LoginApi from "./LoginApi";
import UpdateAPI from "./UpdateAPI";

const UpdateApiMainPage = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [login, setLogin] = useState(false);

  const updateUserData = (newData) => {
    setUser(newData.user);
    setPassword(newData.password);
    setLogin(newData.login);
  };

  // useEffect(() => {
  //   console.log(user);
  //   console.log(password);
  //   console.log(login);
  // }, [user, password, login]);

  return (
    <>
      <ReactNotifications />
      {!login && <LoginApi updateUserData={updateUserData} />}
      {login && (
        <UpdateAPI
          userData={{ user: user, password: password, login: login }}
        />
      )}
    </>
  );
};

export default UpdateApiMainPage;
