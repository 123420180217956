import axios from "axios";
import { useEffect, useState } from "react";

const Image = () => {
  const [elements, setElements] = useState([]);

  const authString = process.env.REACT_APP_API_KEY;
  const headers = {
    Authorization: `Basic ${btoa(authString)}`,
  };

  //Element
  useEffect(() => {
    axios
      .get(`http://localhost:3000/elements`, { headers: headers })
      .then((response) => {
        setElements(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return elements;
};

export default Image;
