import { useRef, useEffect, useState } from "react";
import "../index.css";
import Typed from "typed.js";

const TypedComponent = () => {
  // Create a ref to store the DOM element to be typed into
  const el = useRef(null);
  const typed = useRef(null);

  useEffect(() => {
    const options = {
      strings: [
        "My nickname is keng.",
        "I like coding.",
        "and play games.",
        // "And play Genshin Impact.",
      ],
      typeSpeed: 100,
      backSpeed: 50,
      loop: true,
      smartBackspace: true,
      autoInsertCss: true,
    };

    // Initialize Typed.js
    typed.current = new Typed(el.current, options);

    // Destroy Typed.js instance on unmounting to prevent memory leaks
    return () => {
      typed.current.destroy();
    };
  }, []);

  return (
    <div>
      <span className="type" ref={el}></span>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <div className="home min-h-screen relative select-none" id="home">
        <div className="absolute top-[45%] w-[50%] left-[57.5%] mobile:left-[50%] transform -translate-x-1/2 -translate-y-1/2">
          <div className="text-white flex justify-center">
            <h1 className="text-[10vw] title">Hello World</h1>
          </div>
          <div className="flex justify-center mt-[10vh]">
            <div className="text-white text-[5vw] w-full text-center">
              <TypedComponent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
